import { encrypt } from '../utils/crypto'
import axios from './BaseService'

const WITHDRAWALS_URL = `${process.env.REACT_APP_API_URL}/withdraws/`

export async function getWithdrawals(page, limit, search, paymentMethod, status, ordenation) {
  const endPoint = `${WITHDRAWALS_URL}list${page ? `?page=${page}` : ''}`
  const post = { limit, search, paymentMethod, status, ordenation }
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}

export async function getWithdrawalsFiles(page, limit) {
  const endPoint = `${WITHDRAWALS_URL}files${page ? `?page=${page}` : ''}`
  const post = { limit }
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}

export async function payWithdraw(withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}payWithdraw`
  const post = { withdrawals, coin }
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}

export async function generateFileWithdraw(withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}generatefilewithdraw`
  const post = { withdrawals, coin }
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}

export async function doChargeback(id) {
  const endPoint = `${WITHDRAWALS_URL}chargeback/${id}`
  const response = await axios.get(endPoint)
  return response.data
}
