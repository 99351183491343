const messages = {
  de: {
    translations: {
      a_crase: 'zu',
      about: 'Über Uns',
      above_the_same: 'Über genau dasselbe',
      action: 'Aktion',
      actionTemplates: 'Aktionsvorlagen',
      activate_date: 'Aktivierungsdatum',
      active_automations: 'Aktive Automatisierungen',
      active_connections: 'Aktive Verbindungen',
      active_monitors: 'Aktive Monitore',
      active_users: 'Aktive Benutzer',
      ad: 'Anzeige',
      add: 'Hinzufügen',
      add_option: 'Option hinzufügen',
      add_payment: 'Zahlung hinzufügen',
      add_question: 'Frage hinzufügen',
      add_receipt: 'Quittung hinzufügen',
      add_wallet: 'Geldbörse hinzufügen',
      address: 'Adresse',
      admin: 'Administrator',
      advance: 'Vorschuss',
      affected: 'Betroffen',
      after: 'Nach',
      air_transport: 'Lufttransport',
      ajust_limite: 'Grenzwertanpassung',
      ajust_saldo: 'Saldo-Anpassung',
      alert: 'Alarm',
      alerts: 'Warnungen',
      all: 'Alle',
      all_departments: 'Alle Abteilungen',
      allies_of: 'Verbündete von',
      allRightsReserved: 'Alle Rechte vorbehalten',
      almost_there: 'Fast da',
      already_offset: 'Bereits ausgeglichen',
      already_registered: 'Bereits registriert? Hier klicken und einloggen',
      amount: 'Betrag',
      amount_above_balance: 'Betrag über dem Saldo',
      and: 'Und',
      and_more: 'und mehr',
      answer: 'Antwort',
      ao: 'zu',
      approve: 'Genehmigen',
      April: 'April',
      asc: 'Aufsteigend',
      at: 'An',
      August: 'August',
      automations: 'Automatisierungen',
      awaiting_email_confirmation: 'Warten auf E-Mail-Bestätigung!',
      b_order: 'Bestellung',
      b_orders: 'Bestellungen',
      back: 'Zurück',
      bairroInteresse: 'Stadtteil',
      balance: 'Saldo',
      balance_available: 'Verfügbarer Saldo',
      banned: 'Gesperrt',
      before_contact: 'Bevor Sie uns kontaktieren',
      binary: 'Binär',
      birth: 'Geburt',
      blind_man: 'Blind',
      body_monthly:
        'Ihr monatlicher Beitrag ist abgelaufen, und Ihr BOT TRADER wurde deaktiviert. Bitte bezahlen Sie die Zahlungsanweisung, um Ihre Vorteile wieder zu nutzen.',
      bonus_saldo_trader: 'Bonus-Saldo Trader',
      book_health: 'BookHealth',
      but_possible_register:
        'Es ist weiterhin möglich, sich zu registrieren, aber Sie werden nicht mit einem empfehlenden Freund verknüpft.',
      buy_volume: 'Kaufvolumen',
      buyer: 'Käufer',
      by: 'von',
      bye: 'Bis bald',
      call: 'Anruf',
      call_to: 'Anruf an',
      calls: 'Anrufe',
      cancel: 'Stornieren',
      canceled: 'Abgesagt',
      career_plan: 'Karriereplan',
      casa_verde_amarela: 'Gelbes grünes Haus',
      cash_payment: 'Barzahlung',
      cash_payment_return: 'Rückzahlung der Barzahlung',
      cash_receipt: 'Barquittung',
      change_indicant: 'Indikator ändern',
      change_photo: 'Foto ändern',
      change_plan: 'Plan ändern',
      check_payment: 'Scheckzahlung',
      check_payment_return: 'Rückzahlung der Scheckzahlung',
      check_receipt: 'Scheckquittung',
      choose: 'Wählen',
      cidadeInteresse: 'Stadt',
      city: 'Stadt',
      claims: 'Ansprüche',
      clean: 'Reinigen',
      click_and_know: 'Klicken und erfahren',
      click_to_copy_the_wallet: 'Klicken Sie, um das Portemonnaie zu kopieren.',
      client: 'Klient',
      clients: 'Kunden',
      close: 'Schließen',
      cnh: 'Fahrerlaubnis',
      cnhDate: 'Ausstellungsdatum der Fahrerlaubnis',
      cnpj: 'CNPJ',
      cnpj_invalid: 'Ungültige CNPJ',
      coin: 'Münze',
      color: 'Farbe',
      commercial: 'Kommerziell',
      company: 'Firmenname',
      complement: 'Ergänzen',
      comporRenda: 'Wird Einkommen mit jemandem teilen?',
      compRenda: 'Art der Einkommensbestätigung',
      confirm_approve: 'Genehmigung bestätigen',
      confirm_delete: 'Löschung bestätigen',
      confirm_new_password: 'Neues Passwort bestätigen',
      confirm_new_password_security: 'Sicherheitsneues Passwort bestätigen',
      confirm_password: 'Zugangspasswort bestätigen',
      confirm_password_security: 'Sicherheitspasswort bestätigen',
      confirm_your_new_password: 'Geben Sie Ihr neues Passwort erneut ein',
      confirmed: 'Bestätigt',
      confirmed_email: 'Bestätigte E-Mail',
      congratulations: 'Herzlichen Glückwunsch',
      contact: 'Kontakt',
      contract: 'Vertrag',
      copied: 'Kopiert',
      copy: 'Kopieren',
      copy_success: 'Erfolgreich kopiert!',
      correspondences: 'Korrespondenzen',
      countries: 'Länder',
      country: 'Land',
      cpf: 'CPF',
      cpf_invalid: 'Ungültige CPF',
      create_order_success: 'Bestellung erfolgreich erstellt!',
      created: 'Registriert am',
      cred: 'Kredit',
      cred_binario: 'Binärer Punktekredit',
      cred_deb: 'Kredit-/Debitkarten',
      cred_game_donate: 'Spielguthaben spenden',
      cred_ind_direta: 'Direkte Empfehlungsgutschrift',
      cred_ind_indireta: 'Indirekte Empfehlungsgutschrift',
      cred_mandala: 'Mandala-Gutschrift',
      cred_pagar_com_saldo: 'Zahlung mit Guthabenkredit',
      cred_pontos: 'Punktekredit',
      cred_recb_trader: 'Händlerguthaben empfangen',
      cred_renovacao: 'Gutschrift für Verlängerung',
      cred_saldo_disp: 'Verfügbares Kreditguthaben',
      cred_upgrade: 'UpGrade-Gutschrift',
      credit_card_payment: 'Kreditkartenzahlung',
      credit_card_payment_return: 'Rückzahlung der Kreditkartenzahlung',
      credit_card_receipt: 'Kreditkartenquittung',
      current: 'Aktuell',
      current_password: 'Aktuelles Passwort',
      current_wallet: 'Aktuelles Portemonnaie',
      dashboard: 'Dashboard',
      data_added: 'Daten hinzugefügt',
      data_saved: 'Gespeicherte Daten',
      datas: 'Daten',
      date: 'Datum',
      date_first_installment: 'Datum der ersten Zahlung',
      ddi: 'Ländercode',
      deb_de_trader: 'Händlerabbuchung',
      deb_game_donate: 'Game-Spende abbuchen',
      deb_saldo: 'Saldo abbuchen',
      deb_saldo_disp: 'Verfügbaren Saldo abbuchen',
      deb_saldo_pendent: 'Ausstehenden Saldo abbuchen',
      deb_val_indev: 'Fehlgeschlagener Betrag abbuchen',
      debit_card_payment: 'Debitkartenzahlung',
      debit_card_payment_return: 'Rückzahlung Debitkartenzahlung',
      debit_card_receipt: 'Debitkarteneinzahlung',
      debt: 'Schuld',
      debt_td: 'Schuld',
      December: 'Dezember',
      decImpRenda: 'Einkommensteuererklärung',
      deleted_document: 'Dokument wurde aus Ihrem Konto gelöscht',
      department: 'Abteilung',
      dependents: 'Abhängige',
      desc: 'Abstammung',
      description: 'Beschreibung',
      details: 'Details',
      dev_econom: 'Wirtschaftliche Entwicklung',
      developer: 'Entwickler',
      directly_indicated: 'Direkt angegeben',
      directly_indicateds: 'Direkt angegebene',
      directs: 'Direkt',
      district: 'Stadtteil',
      divorced: 'Geschieden',
      doa_men_receb: 'Spende der Plattform monatlich erhalten',
      doacao_td: 'Spende',
      doctorate_complete: 'Doktor - Abgeschlossen',
      doctorate_incomplete: 'Doktor - Unvollständig',
      document: 'Dokument',
      document_already_registered: 'Dokument bereits registriert',
      documents: 'Dokumente',
      donate: 'Spenden',
      donor: 'Spender',
      dont_know_zip: 'Weiß nicht, PLZ? Klicken Sie hier',
      dou_fe: 'Ich bestätige, dass die obigen Informationen korrekt sind.',
      downloads: 'Dateien herunterladen',
      drop_images: 'Klicken Sie hier oder ziehen Sie Bilder hierhin',
      due_date: 'Fälligkeit',
      edit: 'Bearbeiten',
      edit_option: 'Option bearbeiten',
      edit_question: 'Frage bearbeiten',
      email: 'E-Mail',
      email_already_registered: 'E-Mail bereits registriert',
      email_and_or_password_wrong: 'E-Mail und/oder Passwort falsch!',
      email_for_login: 'Diese E-Mail wird Ihr Login sein',
      email_or_password_wrong: 'E-Mail oder Passwort falsch',
      employee: 'Mitarbeiter',
      employees: 'Mitarbeiter',
      empty: 'Leer',
      enter_your_new_password: 'Geben Sie Ihr neues Passwort ein',
      entrada: 'Hat einen Einzahlungsbetrag? Wenn JA, welcher Betrag',
      entries_until_today: 'Einträge bis heute',
      error_confirm_password: 'Bestätigung und neues Passwort müssen gleich sein',
      error_email: 'Ungültiges E-Mail-Format',
      error_password: 'Falsches Passwort',
      error_phone: 'Ungültige Telefonnummer',
      estadoInteresse: 'Staat',
      estimate: 'Geschätzt',
      exchange: 'Austausch',
      expired: 'Abgelaufen',
      extorno_td: 'Stornierung',
      extra: 'Extra',
      extract: 'Auszug',
      extracts: 'Auszüge',
      fantasy: 'Fantasie',
      fatherName: 'Vatername',
      February: 'Februar',
      fee: 'Abhebungsgebühr',
      feedback: 'Rückmeldung',
      feminine: 'Weiblich',
      fgts: 'Ihr FGTS-Betrag',
      field_required: 'Pflichtfeld',
      final: 'Ende',
      finance: 'Finanzen',
      financial: 'Finanz',
      financial_payment: 'Finanzzahlung',
      financial_payment_return: 'Rückzahlung Finanzzahlung',
      financial_receipt: 'Finanzeinzahlung',
      financing: 'Finanzierung',
      financings: 'Finanzierungen',
      first_10_pages: 'Erste 10 Seiten',
      fisica: 'Natürliche Person',
      for_year: 'Pro Jahr',
      forget_password: 'Passwort vergessen',
      forgot_password: 'Passwort vergessen',
      form: 'Form',
      form_of_payment: 'Zahlungsart',
      found: 'Gefunden',
      founds: 'Gefunden',
      free: 'Frei',
      friend_indicated: 'Von meinen Freunden empfohlen',
      friend_indicateds: 'Von meinen Freunden empfohlene',
      fuel: 'Kraftstoff',
      full_name: 'Vollständiger Name',
      funcPublico: 'Ist öffentlicher Angestellter',
      fundamental_complete: 'Grundschule - Abgeschlossen',
      fundamental_incomplete: 'Grundschule - Unvollständig',
      ganh_diario: 'Tägliche Gewinne',
      gender: 'Geschlecht',
      grand_total: 'Gesamtsumme',
      group: 'Gruppe',
      has_withdraw_today: 'Heute wurde bereits eine Abhebung beantragt',
      high: 'Hoch',
      home: 'Startseite',
      how_create_binance_apikey: 'So erstellen Sie den API-Schlüssel auf Binance',
      how_it_works: 'Wie es funktioniert',
      how_to_call: 'Wie möchten Sie genannt werden',
      id_td: 'Direkte Empfehlung',
      images: 'Bilder',
      in_construction: 'In Bau',
      in_order: 'In Ordnung',
      in_stock: 'Auf Lager',
      indicant: 'Empfehlender',
      indicated_by: 'Sie werden empfohlen von',
      indicated_not_found: 'Der angegebene Empfehlende wurde nicht gefunden...',
      indicateds: 'Empfohlene',
      indirects: 'Indirekte',
      info: 'Informationen',
      info_contact: 'Kontaktinformationen',
      initial: 'Anfang',
      insert_new: 'Neu einfügen',
      installments: 'Raten',
      intelligence: 'Intelligenz',
      interesse: 'Interessengebiet',
      invalid_contact: 'Ungültiges Kontaktformat',
      invalid_images: 'Ungültige Bilder',
      invalid_token: 'Ungültiges Token',
      invite: 'Einladen',
      invite_friends: 'Lade deine Freunde ein',
      January: 'Januar',
      July: 'Juli',
      June: 'Juni',
      juridica: 'Juristische Person',
      know_exact_result: 'Kennen Sie das genaue Ergebnis?',
      lead: 'Gemeinsam werden wir eine neue Zukunft aufbauen.',
      learn_more: 'Erfahre mehr',
      left: 'Links',
      left_preferential: 'Bevorzugt die linke Seite',
      legalNature: 'Rechtsform',
      level: 'Niveau',
      limite_80:
        'Sie haben {{perc}} Ihres Limits erreicht.\n Verbessern Sie sich und vermeiden Sie die Aussetzung Ihrer Erträge.',
      limite_excedido: 'Überschreitung des Limit-Debits',
      linear: 'Linear',
      link_email: 'Klicken Sie auf den Link, der an die registrierte E-Mail gesendet wurde!',
      link_invite_copy: 'Link zum Teilen erfolgreich kopiert',
      liquid: 'Flüssig',
      list: 'Liste',
      loading: 'Laden',
      location: 'Ort',
      locked: 'Gesperrt',
      login: 'Einloggen',
      logout: 'Ausloggen',
      low: 'Niedrig',
      male: 'Männlich',
      manage_users: 'Benutzer verwalten',
      manager: 'Manager',
      mandala: 'Mandala',
      mandalas: 'Mandalas',
      manufacturer: 'Hersteller',
      March: 'März',
      maritalStatus: 'Familienstand',
      market: 'Markt',
      married: 'Verheiratet',
      master_complete: 'Master - Abgeschlossen',
      master_incomplete: 'Master - Unvollständig',
      max_payment: 'Maximale Zahlung',
      May: 'Mai',
      message: 'Nachricht',
      messageNotSent: 'Nachricht konnte nicht gesendet werden',
      messageSent: 'Ihre Nachricht wurde erfolgreich gesendet',
      middle_complete: 'Mittelschule - Abgeschlossen',
      middle_incomplete: 'Mittelschule - Unvollständig',
      min_amount_error: 'Der Betrag muss mindestens sein: ',
      min_withdraw: 'Mindestabhebungsbetrag',
      missing: 'Fehlen',
      mission: 'Mission',
      mission_vision_values: 'Mission, Vision und Werte',
      model: 'Modell',
      monitors: 'Monitore',
      monthly: 'Monatlich',
      motherName: 'Name der Mutter',
      movements: 'Bewegungen',
      msg_renovacao: 'Sie haben Ihr Gewinnlimit erreicht. Erneuern Sie Ihr Paket.',
      my_account: 'Mein Konto',
      my_wallet: 'Mein Portemonnaie',
      mystock: 'Mein Bestand',
      name: 'Name',
      nascMaisVelhoRenda2: 'Geburtsdatum des Ältesten',
      nationality: 'Nationalität (Land, in dem Sie geboren wurden)',
      naturalness: 'Geburtsort (Stadt, in der Sie geboren wurden)',
      nature: 'Natur',
      network: 'Netzwerk',
      new: 'Neu',
      new_call: 'Neuer Anruf',
      new_financing: 'Neue Finanzierung',
      new_order: 'Neue Bestellung',
      new_password: 'Neues Passwort',
      new_password_security: 'Neues Sicherheitspasswort',
      new_seller: 'Neuer Verkäufer',
      new_wallet: 'Neues Portemonnaie',
      news: 'Nachrichten',
      next: 'Nächstes',
      nick: 'Benutzer (Anmeldung)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS bereits registriert',
      no: 'Nein',
      no_approve: 'Noch nicht genehmigen',
      no_delete: 'Nicht löschen',
      no_literate: 'Nicht alphabetisiert',
      no_repeat: 'Die Daten können nicht wiederholt werden',
      no_results_found: 'Keine Ergebnisse gefunden',
      no_services_or_products_added: 'Keine Dienstleistungen oder Produkte hinzugefügt',
      no_settings: 'Gehe zu Einstellungen und fülle deine Daten aus.',
      none_friends_indicated: 'Ich habe noch keine Freunde empfohlen',
      not_authorized: 'Nicht autorisiert',
      not_found: 'Nicht gefunden',
      not_login: 'Anmeldung nicht möglich, überprüfen Sie die Daten und versuchen Sie es erneut!',
      not_orders: 'Keine Zahlungsaufträge',
      not_permission: 'Sie haben keine Berechtigung, auf diese Seite zuzugreifen',
      not_registered: 'Noch nicht registriert? Klicken Sie hier und registrieren Sie sich',
      not_results: 'Kein Eintrag gefunden',
      not_self_register: 'Registrierung nicht möglich',
      not_today_entry: 'Heute gab es noch keine Einträge',
      notfound_ad: 'Keine Anzeige gefunden',
      November: 'November',
      number: 'Nummer',
      obs: 'Bemerkungen',
      obs_pay:
        'Wenn Ihr Broker Gebühren vom gesendeten Betrag abzieht, müssen Sie die Werte dieser Gebühren manuell hinzufügen.',
      October: 'Oktober',
      of: 'von',
      off: 'Aus',
      on_total: 'Vom Gesamtbetrag',
      open: 'Offen',
      opening: 'Eröffnung',
      operation: 'Betrieb',
      ordenation: 'Ordnung',
      order: 'Bestellung',
      order_book: 'Auftragsbuch',
      order_not_alowed_to_pay: 'Bestellung nicht autorisiert, mit Guthaben zu zahlen',
      order_not_found: 'Bestellung nicht gefunden',
      order_payed: 'Bestellung #{{id}} erfolgreich bezahlt',
      orders: 'Bestellungen',
      other: 'Andere',
      other_services_or_products: 'Andere Dienstleistungen oder Produkte',
      paid: 'Bezahlt',
      paid_date: 'Zahlungsdatum',
      partial: 'Teilweise bezahlt',
      passport: 'Reisepass',
      password: 'Passwort',
      password_security: 'Sicherheitspasswort',
      pay: 'Bezahlen',
      pay_exact: 'Zahlen Sie den Betrag in {{coin}}',
      pay_generate: 'Zahlung generieren',
      pay_selected: 'Bezahlen ausgewählt',
      pay_value_in: 'Zahlen Sie den Betrag ein',
      pay_with_balance: 'Bezahlen Sie mit Guthaben',
      payable: 'Zahlen',
      payer: 'Zahler',
      payment: 'Zahlung',
      ped_retirada: 'Auszahlungsantrag',
      ped_retirada_gan_diario: 'Antrag auf Auszahlung des Tageseinkommens',
      pendent: 'Ausstehend',
      pendent_order: 'Sie haben ausstehende Bestellungen',
      people: 'Menschen',
      person: 'Person',
      person_found_in_other_prefecture: 'Person, die bereits in einer anderen Gemeinde gemeldet ist',
      personal: 'Jungs',
      personal_addresses: 'Adressen',
      personal_contacts: 'Kontakte',
      personal_data: 'persönliche Daten',
      personal_infos: 'Information',
      personal_pcva: 'Programm des Grünen und Gelben Hauses',
      phone: 'Telefonnummer',
      photo: 'Foto',
      pix: 'PIX',
      pix_payment: 'Zahlung in PIX',
      pix_payment_return: 'Rückerstattung der Zahlung in PIX',
      pix_receipt: 'Empfangen in PIX',
      pl_carreira: 'Karriereweg',
      plan: 'Wohnung',
      plates: 'Platten',
      please_wait: 'Bitte warten',
      plus: 'Plus',
      point: 'Punkt',
      points: 'Punkte',
      portfolio: 'Portfolio',
      pos_complete: 'Lato sensu – Vollständig',
      pos_incomplete: 'Lato sensu – Unvollständig',
      prefecture: 'Rathaus',
      prefecture_addresses: 'Adressen',
      prefecture_contacts: 'Kontakte',
      prefecture_data: 'Rathausdaten',
      prefecture_infos: 'Information',
      prefectures: 'Rathäuser',
      prev: 'Vorherige',
      price: 'Preis',
      print: 'Ausdrucken',
      privacy_policies: 'Datenschutzrichtlinien',
      private_works: 'Private Werke',
      profession: 'Beruf',
      profile: 'Profil',
      program: 'Programm',
      programs: 'Software',
      project: 'Projekt',
      projects: 'Projekte',
      public_works: 'Öffentliche Bauarbeiten',
      purchase: 'Kaufen',
      qualify_binary: 'Dual-Point-Qualifikation',
      qualquer: 'Beliebig',
      quantity: 'Menge',
      quotation: 'Preis',
      read: 'Lesen',
      receivable: 'Bekommen',
      recipient: 'Empfänger',
      recommendations: 'Empfehlungen',
      redefine_password: 'Passwort neu definieren',
      register: 'Aufzeichnen',
      register_people: 'Registrierung von Personen',
      register_wallet: 'Registrieren Sie ein Wallet, um Abhebungen vorzunehmen',
      registers: 'Aufzeichnungen',
      registers_users: 'Benutzerregistrierungen',
      reject: 'Ablehnen',
      remaining: 'Übrig',
      remove: 'Zu entfernen',
      rendaIndividual: 'Individuelles Bruttoeinkommen',
      renew: 'Erneuern',
      renew_mandala: 'Mandala-Erneuerung',
      reply: 'Antworten',
      reports: 'Berichte',
      required_data_not_sent: 'Erforderliche Daten wurden nicht gesendet',
      resend_email: 'Sende die E-Mail erneut',
      residential: 'Wohnen',
      responded: 'Antwortete',
      restricao: 'Gibt es Einschränkungen bezüglich Ihres Namens? (SPC/Serasa)',
      result: 'Ergebnis',
      results: 'Ergebnisse',
      resume_data: 'Datenzusammenfassung',
      revoked: 'Aufgehoben',
      rg: 'Allgemeine Registrierung (Identität)',
      rgAgency: 'RG-Emittent',
      rgDate: 'RG-Ausstellungsdatum',
      rgUf: 'Bundesstaat RG',
      right: 'Rechts',
      right_preferential: 'Bevorzuge die rechte Seite',
      roi: 'Tägliches Einkommen',
      sale: 'Verkauf',
      saq_duplicado: 'Doppelte Auszahlung',
      save: 'Speichern',
      schooling: 'Ausbildung',
      search: 'Suchen',
      secretary: 'Sekretär',
      security_password: 'Sicherheitspasswort',
      see: 'Um zu sehen',
      see_advents: 'Sehen Sie sich die Anzeigen an',
      see_call: 'Anruf ansehen',
      see_details: 'siehe Einzelheiten',
      see_details_wallet: 'Wallet-Details anzeigen',
      see_financing: 'Finanzierung ansehen',
      see_order: 'Bestellung ansehen',
      see_user: 'Benutzer anzeigen',
      select_vehicle: 'Fahrzeug auswählen',
      self_register: 'Registrieren',
      sell_volume: 'Verkaufsvolumen',
      seller: 'Verkäufer',
      send_call: 'Anruf senden',
      send_to: 'Senden an',
      September: 'September',
      settings: 'Einstellungen',
      share_your_link: 'Teilen Sie Ihren Link',
      show: 'Anzeigen bis',
      show_from: 'Anzeige ab',
      side: 'Seite',
      sign_in_to_start_your_session: 'Melden Sie sich an, um Ihre Sitzung zu starten',
      sign_up: 'Registrieren',
      single: 'Einzel',
      sold: 'Verkauft',
      state: 'Zustand',
      status: 'Status',
      strategies: 'Strategien',
      street: 'Straße',
      subject: 'Thema',
      submit: 'Senden',
      submit_placeholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
      subscribe: 'Melden Sie sich an, um unsere Neuigkeiten zu erhalten',
      subtitle: 'Untertitel',
      success_update_user: 'Benutzer erfolgreich aktualisiert!',
      success_wallet: 'Portfolio erfolgreich aktualisiert!',
      suggestions: 'Vorschläge',
      superior_complete: 'Absolvent',
      superior_incomplete: 'Unvollständig höher',
      supervisor: 'Aufsicht',
      support: 'Unterstützung',
      support_materials: 'Unterstützende Materialien',
      suspended: 'Ausgesetzt',
      symbol: 'Symbol',
      symbols: 'Symbole',
      system: 'System',
      target: 'Ziel',
      telegram_chat_id: 'Telegram-Chat-ID',
      tempoCarteira: 'Seit wann sind Sie in Ihrem Portfolio eingetragen?',
      terms: 'Ich akzeptiere die Nutzungsbedingungen',
      text_hint_binance:
        'Geben Sie beim Erstellen des API-Schlüssels auf Binance die IP in den Zugriffsbeschränkungen ein:\n172.105.78.139\nDann wählen Sie die Optionen aus:\n EUROPÄISCHE OPTIONEN AKTIVIEREN\n SPOT- & MARGIN-HANDEL AKTIVIEREN',
      this_value_in: 'Dieser Wert in',
      ticker_health: 'TickerHealth',
      title: 'Titel',
      to: 'Bis',
      to_pay: 'Ausschalten',
      to_receive: 'Forderungen',
      today_entry: 'Heutiger Eintrag',
      token_not_found_or_already_used: 'Token nicht gefunden oder bereits verwendet',
      total: 'Gesamt',
      total_entry: 'Gesamteintrag',
      traderbot: 'Bot-Händler',
      transf_credito: 'Überweisungskredit',
      transf_debito: 'Lastschrift überweisen',
      transfer_payment: 'Zahlung per Überweisung',
      transfer_payment_return: 'Rückerstattung der Zahlung per Überweisung',
      transfer_receipt: 'Quittung bei Überweisung',
      transparency: 'Transparenz',
      tx_adm_cred: 'Steuern verwalten',
      tx_adm_deb: 'Steuern verwalten',
      tx_retirada: 'Abhebegebühr',
      tx_retirada_gan_diario: 'Tägliche Auszahlungsgebühr',
      type: 'Typ',
      update: 'Aktualisieren',
      update_wallet: 'Wallet aktualisieren',
      upgrade: 'Verbesserung',
      used: 'Gebraucht',
      user: 'Benutzer',
      user_active: 'Aktiver Nutzer',
      user_already_exists: 'Benutzer existiert bereits!',
      user_and_or_password_wrong: 'Falscher Nutzername und / oder Passwort!',
      user_inactive: 'Inaktiver Benutzer',
      user_not_found: 'Benutzer nicht gefunden',
      user_not_logged: 'Benutzer nicht angemeldet',
      user_or_password_wrong: 'falscher Benutzername oder falsches Passwort',
      users: 'Benutzer',
      users_list: 'Benutzerliste',
      valid_images: 'Gültige Bilder',
      valorRenda2: 'Wie hoch ist das Zweiteinkommen?',
      value: 'Wert',
      value_donate_on: 'Spendenwert in',
      value_financed: 'Finanzierter Betrag',
      value_installment: 'Ratenwert',
      values: 'Werte',
      vehicle: 'Fahrzeug',
      vehicles: 'Fahrzeuge',
      view_order: 'Bestellung anzeigen',
      vision: 'Vision',
      visitor: 'Besucher',
      voucher: 'Gutschein',
      wait_confirm: 'Warte auf Bestätigung',
      waiting: 'Warten',
      waiting_email: 'Die Registrierung war erfolgreich, jetzt müssen Sie nur noch Ihre E-Mail bestätigen.',
      waiting_payment: 'Warten auf Zahlung',
      wallet: 'Portfolio',
      wallet_generate: 'Portfolio erstellen',
      wallets: 'Geldbörsen',
      want_donate: 'Ich möchte spenden',
      want_now_more_about: 'Ich möchte mehr darüber erfahren',
      warning: 'AUFMERKSAMKEIT',
      we_are: 'Sind',
      webhooks: 'Webhooks',
      welcome: 'Willkommen',
      who_we_are: 'Wer wir sind',
      who_we_are_subtitle: 'Eine kurze Beschreibung des',
      widower: 'Witwer',
      will_expire_in: 'läuft ab',
      with: 'mit',
      withdraw: 'Rückzug',
      withdraw_sended_email:
        'Auszahlung beantragt, überprüfen Sie Ihre E-Mails und klicken Sie zur Autorisierung auf den Link!',
      withdrawal: 'Abhebungen',
      withdrawals: 'Abhebungen',
      year: 'Jahr',
      years: 'Jahre',
      yes: 'Ja',
      you_have: 'Du hast',
      your_country: 'Dein Land',
      your_dashboard: 'Dies ist Ihre Systemsteuerung.',
      your_link: 'Dein Link',
      zipcode: 'PLZ',
      withdrawal_sended: 'Auszahlung gesendet',
      withdrawal_pending:
        'Auszahlung bereits beantragt! \nBitte warten Sie auf die Zahlung, um eine weitere Auszahlung vorzunehmen.',
      limite_80_top:
        'Sie haben {{perc}} Ihr Limit erreicht.\n Erwarten Sie Ihre Verlängerung und vermeiden Sie eine Aussetzung Ihres Einkommens.',
      game: 'Spiel',
      games: 'Spiele',
      winning_numbers: 'Gezogene Zahlen',
      your_numbers: 'Ihre Zahlen',
      add_numbers: 'Zahlen hinzufügen',
      next_draw: 'Nächste Ziehung',
      can_bet_until: 'Sie können bis zu wetten',
      your_bets: 'Ihre Wetten',
      add_bet: 'Wette hinzufügen',
      bet: 'Wette',
      bets: 'Wetten',
      numbers: 'Zahlen',
      special_numbers: 'Sondernummern',
      resume: 'Zusammenfassung',
      choosed_numbers: 'Ausgewählte Zahlen',
      choosed_special_numbers: 'Spezielle Nummern ausgewählt',
      bets_finished: 'Abgerechnete Wetten',
      prize_draw: 'Gewinnspiel',
      balance_network: 'Netzwerkbalance',
      telegram: 'Telegramm',
      logs: 'Protokolle',
      release_withdrawals: 'Auszahlungen freigeben',
      bot_active: 'Bot freigeben',
      available: 'Verfügbar',
      verify: 'Überprüfen',
      rescue: 'Rettung',
      awarded: 'Ausgezeichnet',
      rescued: 'Gerettet',
      repeat: 'Wiederholen',
      draw_date: 'Ziehungsdatum',
      bet_date: 'Wettdatum',
      drawn_numbers: 'Gezogene Zahlen',
      awardeds_numbers: 'Preisnummern',
      no_award: 'Kein Preis',
      you_hit_number: 'Sie haben {{qty}} Nummer richtig angegeben',
      you_hit_numbers: 'Sie haben {{qty}} Zahlen richtig angegeben',
      lottery: 'Lotterie',
      lotteries: 'Lotterien',
      balls: 'Bälle',
      special_balls: 'Sonderangebote',
      levy: 'Sammlung',
      major_awarded: 'mehr Bälle',
      hits: 'Treffer',
      draw_due: 'Limit für Wetten',
      close_game: 'Knappes Spiel',
      draw_due_greater_than_draw_date: 'Das Wettlimit muss mindestens {{timeLimit}} Minuten vor der Ziehung liegen',
      draw_date_less_than_now:
        'Das Ziehungsdatum muss mindestens {{timeLimit}} Minuten nach dem aktuellen Datum liegen',
      balance_game: 'Balance für Spiele',
      your_balance: 'Dein Kontostand',
      your_balance_total: 'Ihr Gesamtguthaben',
      game_package: '{{qty}} Zahlen (kann {{prize}} Mal gewinnen)',
      choose_package: 'Wählen Sie das Paket',
      error_bet: 'Wette kann nicht gesendet werden',
      do_complete: 'Fertigstellen',
      success_bet: 'Wette gesendet',
      you_bet_number: 'Sie setzen {{qty}} Zahlen',
      winner_msg: 'und gewann {{prize}} Mal',
      prize: 'Vergeben',
      balance_not_enough: 'Unzureichende Mittel',
      addbalance: 'Fügen Sie den Spielen Balance hinzu',
      convertbalance: 'Salden umrechnen',
      convertbalance_title: 'Wandeln Sie das verfügbare Guthaben in ein Spielguthaben um',
      can_award: 'Du kannst gewinnen',
      emission: 'Emission',
      validation: 'Validierung',
      participant: 'Teilnehmer',
      concourse: 'Wettbewerb',
      draw_hours: 'Ziehzeit',
      qty_dezenas: 'Anzahl der Zehner',
      bet_amount: 'Einsatzbetrag',
      share: 'Teilen',
      shared: 'Bereit zum Teilen',
      simulation: 'Simulation',
      valuePay: 'Zu zahlender Betrag',
      quotas_paid_success: 'Zahlung erfolgreich',
      paids_today: 'Heute getätigte Zahlungen',
      no_paids_today: 'Heute wurden keine Zahlungen getätigt',
      perc: 'Prozentsatz',
      define_percent: 'Prozentsatz festlegen',
      success_update: 'Aktualisierung erfolgreich',
      define_percent_next_payment: 'Prozentsatz für das nächste Zahlungsbetrag festlegen',
      better: 'Wettberater',
      lottery_consultant: 'zugehöriger Berater',
      create: 'Erstellen',
      doclose: 'Schließen',
      lottery_updated: 'Lottery aktualisiert',
      lottery_created: 'Lottery erstellt',
      award_packages: 'Gewinn-Pakete',
      qty: 'Anzahl',
      multiplier: 'Faktor',
      maxValue: 'Maximalwert',
      limitRepeat: 'Wiederholungsgrenze',
      draw_day: 'Ziehungsdatum',
      day: 'Tag',
      hour: 'Uhr',
      minutes: 'Minuten',
      ytb_channel_id: 'Youtube-Kanal-ID',
      tutorial: 'Tutorial',
      video_url: 'Video-URL',
      tutorial_created: 'Tutorial erstellt',
      tutorial_deleted: 'Tutorial gelöscht',
      tutorial_error_delete: 'Tutorial kann nicht gelöscht werden',
      tutorial_updated: 'Tutorial aktualisiert',
      tutorial_error_update: 'Tutorial kann nicht aktualisiert werden',
      english: 'Englisch',
      spanish: 'Spanisch',
      portuguese: 'Portugiesisch',
      select_language: 'Sprache auswählen',
      language: 'Sprache',
      add_balance_games: 'Fügen Sie den Spielen Balance hinzu',
      consultant_indication: 'Beratertermin',
      mensal_activation: 'Jährliche Aktivierung',
      sunday: 'Sonntag',
      monday: 'Zweite',
      tuesday: 'Dritte',
      wednesday: 'Vierte',
      thursday: 'Fünfte',
      friday: 'Freitag',
      saturday: 'Samstag',
      title_binary: 'Legen Sie für jeden Tag Dual-Point-Prozentsätze fest',
      ok_perc_change: 'Prozentsätze wurden erfolgreich geändert',
      cred_deb_title: 'Kredit- oder Sollsaldo',
      send: 'Senden',
      voucher_pendent: 'Gutschein ausstehend',
      leader_pendent: 'Ausstehender Anführer',
      voucher_approved: 'Gutschein genehmigt',
      leader_approved: 'Anerkannter Anführer',
      no_binary_at_today: 'An diesem Tag ist kein Binary registriert',
      generate_file: 'Datei generieren',
      withdrawals_file_created: 'Auszahlungsdatei erstellt',
      withdrawals_sended: 'Auszahlungsanfragen gesendet',
      see_generated_files: 'Generierte Dateien anzeigen',
      files: 'Dateien',
      file: 'Datei',
      conversion: 'Umrechnung zwischen Salden',
      betting_commission: 'Wettkommission',
      be_a_consultant: 'Werden Sie Lotterieberater und verdienen Sie viel mehr',
      msg_be_a_consultant:
        'Die Lotter Global Profit Division.\nEmpfehlungsbonus (auf Paketverkäufe)\nDual-Punkte-Bonus\nund vieles mehr.',
      auto_renovations: 'Automatische Verlängerungen',
      auto_renove_binary: 'Dual-Point-Selbsterneuerung',
      auto_renove_quotas: 'Selbsterneuerung der Ziele (220 %)',
      auto_renove_anual: 'Jährliche Selbsterneuerung',
      on: 'In Verbindung gebracht',
      change_success: 'Erfolgreich geändert',
      anual: 'Jährlich',
      only_monday: 'Auszahlungen sind jeden Montag möglich',
      transferency: 'Überweisen',
      max_amount: 'Höchster Wert',
      info_better:
        '- Erhalten Sie 100 % Ihres Startpakets und Ihrer Verlängerungen in CREDITS für Wetten.\n\n- Sie können jederzeit neue Wettguthaben aufladen.\n\n- Nehmen Sie an jedem Geschäftsplan teil und können Sie durch direkte und indirekte Empfehlungen verdienen , Binary Bonus, Unilevel, Karriereplan und Auszeichnungen.',
      info_consultant:
        '- ERHÄLT KEINE CREDITS für Wetten, DER GRÜNDUNGSBERATER verkauft seine CREDITS für Wetten aus seinem Einstiegspaket und Verlängerungen für The Lotter, um diese auszuhandeln, und erhält daher werktags BIS ZU 1 % auf den Wert seines Pakets, bis dieser 220 % erreicht kann dann auf Wunsch verlängert werden.\n\n- Sie können Ihr Wettguthaben jederzeit aufladen.\n\n- Nehmen Sie an jedem Businessplan teil und verdienen Sie durch direkte und indirekte Empfehlungen, Dual-Punkte-Bonus, Unilevel, Karriereplan und Auszeichnungen.',
      confirm: 'Bestätigen',
      send_new_token:
        'Sobald die Bestätigung an Ihre E-Mail gesendet wurde, klicken Sie zur Bestätigung auf Ihre E-Mail',
      email_resended: 'Bestätigung an Ihre E-Mail gesendet. Klicken Sie zur Bestätigung auf Ihre E-Mail',
      expired_send_new_token: 'Neue Bestätigung an Ihre E-Mail gesendet. Klicken Sie zur Bestätigung auf Ihre E-Mail',
      become_a_consultant: 'Upgraden Sie und erhalten Sie viel mehr',
      register_ok: 'Erfolgreich registriert',
      ticket_number: 'Zahlungskartennr',
      conversion_to_bet: 'Umwandlung in Wette',
      conversion_balance_games: 'Umrechnung des Guthabens in Spielguthaben',
      conversion_balance_lotterprocoin: 'Guthabenumrechnung in Aktivierungen',
      conversion_lotterprocoin_games: 'Konvertierung von Aktivierungen in Spielguthaben',
      conta_corrente: 'Aktuelles Konto',
      balance_games: 'Spielbalance',
      transf_debit_lotterProCoin: 'Aktivierungen-Guthabentransfer',
      deb_saldo_lotterProCoin: 'Aktivierungen-Guthabenabbuchung',
      transf_credit_lotterProCoin: 'Guthaben auf Aktivierungen übertragen',
      live_now: 'Jetzt live',
      offline: 'Offline',
      tutorials: 'Tutorials',
      msg_renove_binary:
        'Sie haben bereits einen weiteren Monat der letzten monatlichen Aktivierung abgeschlossen und haben die automatische monatliche Aktivierung nicht aktiviert.\nAus diesem Grund ist Ihr Binary inaktiv.\nZahlen Sie eine Aufforderung zum Hinzufügen eines Spielguthabens in Höhe von 25,00 $, um die monatliche Aktivierung durchzuführen .',
      deb_balance_games: 'Guthabenabbuchung in Spielguthaben',
      cred_saldo_lotterProCoin: 'Guthabenverlauf in Aktivierungen',
      live_hours: 'Live-Stunden',
      choose_side_preferential: 'Wählen Sie die bevorzugte Seite (derzeit: {{side}})',
      count: 'Anzahl',
      historics: 'Historik',
      balance_lotterProCoin: 'Aktivierungsbilanz',
      msg_binary_tree_1: 'Drücken Sie den gewünschten Benutzer 1 Sekunde lang, um die Informationen anzuzeigen',
      msg_binary_tree_2:
        'Klicken Sie einmal auf den gewünschten Benutzer, um die nächsten Benutzer in der Binärdatei zu sehen.',
      change_security_password: 'Passwort für Sicherheit aendern',
      info_pass_security: 'Das Sicherheitspasswort wird zur Durchführung von Finanztransaktionen verwendet',
      redefine_security_password: 'Passwort für Sicherheit neu definieren',
      redefined_password: 'Zugangspasswort erfolgreich zurückgesetzt!',
      click_to_enter: 'Klicken Sie hier, um einzutreten',
      title_banner: 'Willkommen bei {{company}}, {{name}}!',
      descryption_banner:
        'Kopieren Sie Ihren Registrierungslink unten und teilen Sie ihn mit Ihren Freunden, um Ihre Einnahmen zu maximieren',
      no_binaries: 'Das Netzwerk dieses Benutzers kann nicht angezeigt werden',
      invalid_wallet: 'Ungültige Wallet',
      valid_wallet: 'Gültige Lizenz',
      wallet_usdc: 'USDC-Wallet',
      comunicado_20240705:
        'Das Aibot-Team gibt stolz bekannt, dass unsere KI nach Beginn des Lizenzverkaufs betriebsbereit ist. \n Wir bitten alle, ihre Daten zu aktualisieren und ihre Dokumente einzusenden, um eine großartige Reise zu beginnen. \n\nAlles beginnt 24 Stunden nach dem Absenden und Genehmigen der Dokumente für jedes Konto. \n\n Nutzen Sie die Gelegenheit, Ihre Lizenzen zu erwerben, da diese begrenzt sind, und garantieren Sie den doppelten Wert innerhalb Ihres Binance- oder Coinbase-Brokers',
      customer: 'Kunde',
			estorno_comission: 'Provisionsstornierung',
			income: 'Einnahmen',
    },
  },
}
export { messages }
